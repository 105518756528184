/* Back-to-Top Button Styles */
.back-to-top-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0056B3;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    font-size: 16px;
    cursor: pointer;
    position: fixed;
    bottom: 140px;
    right: 20px;
    z-index: 1000;
  }
  
  .back-to-top-button:hover {
    background-color: #0056B3;
  }
  