.servicebs-hero {
    height: 70vh;
    width: 100%;
    background: url('../../components/assets/servicebg.jpg') no-repeat center/cover;
    color: white;
    /* text-align: center; */
    position: relative;
    padding: 30px;
    
}
.servicebs-hero-content{
    padding-left: 80px;
    padding-right: 80px;
}

.servicebs-hero-content h1 {
    margin-top: 110px;
    font-size: 50px;
    font-weight: bold;
}

.servicebs-hero-content p {
    font-size: 22px;
    line-height: 1.5;
    padding-top: 20px;
}


.servicebs-hero-content span {
    color: #ff7b00;
    font-weight: bold;
}

@media (max-width: 768px) {
    .servicebs-hero  {
        height: 60vh;
        width: 100%;
        padding-top: 10px;
    }
    

    .navbar-brand {
        flex-grow: 1;
        text-align: left;
    }

    .navbar-toggler {
        position: absolute;
        right: 15px;
    }

    .servicebs-hero-content h1 {
        margin-top: 50px;
        font-size: 32px;
    }

    .servicebs-hero-content p {
        font-size: 16px;
        
    }

    .hero-buttons {
        flex-direction: column;
        gap: 10px;
    }

    .view-button, .contact-button {
        width: 100%;
        padding: 8px 16px;
        font-size: 14px;
    }
    .servicebs-hero-content{
        padding-left: 0px;
        padding-right: 0px;
    }
}

/* main page */
  .section {
    margin-top: 50px;
  }
  
  .image-container img {
    max-width: 100%;
    height: auto;
    /* border-radius: 10px; */
  }
  
  .text-container {
    text-align: left;
  }
  
  .text-container h1 {
    font-size: 2.9rem;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 1rem;
  }
  
  .text-container h1 .highlight {
    color: #ff7a00; /* Highlight color for "Brand Strategy" */
  }
  
  .text-container p {
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
    line-height: 1.5;
  }
  
  .text-container button {
    font-size: 1rem;
    padding: 0.75rem 1.5rem;
    border-radius: 25px;
  }
  
  @media (max-width: 768px) {

  
    .text-container {
      text-align: center;
      margin-top: 20px;
    }
  
    .text-container h1 {
      font-size: 2rem;
    }
  
    .text-container p {
      font-size: 1rem;
    }
  }
  
/* expert experience */
/* General Section Styling */
.expert-solutions {
    border-radius: 8px;
    padding: 40px 20px;
    text-align: left;
  }
  
  .expert-solutions h2 {
    font-size: 2rem;
    font-weight: 700;
    color: #2b3a67;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  
  .expert-solutions h2 .highlight {
    color: #f77f00;
  }
  
  .solution-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 10px 0;
    border-bottom: 1px solid #e0e0e0; /* Light border between items */
  }
  
  .solution-image {
    width: 60px;
    height: 60px;
    object-fit: contain;
    padding-right: 20px;
    border-right: 2px solid #ff7b00;
    /* border-radius: 8px; */
    margin-right: 15px; /* Space between the image and border */
  }
  
  .solution-content {
    flex-grow: 1;
    border-left: 1px solid #e0e0e0; /* Vertical line between image and text */
    padding-left: 15px;
  }
  
  .solution-title {
    font-size: 1.2rem;
    font-weight: 600;
    color: #2b3a67;
    margin-bottom: 8px;
  }
  
  .solution-description {
    font-size: 0.95rem;
    color: #5a5a5a;
    line-height: 1.7;
    max-width: 500px;
  }
  
  /* Responsive Styling */
  @media (max-width: 768px) {
    .expert-solutions h2 {
      font-size: 1.5rem;
    }
  
    .solution-image {
      width: 50px;
      height: 50px;
    }
  
    .solution-title {
      font-size: 1rem;
    }
  
    .solution-description {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 576px) {
    .expert-solutions {
      padding: 20px 10px;
    }
  
    .solution-container {
      flex-direction: column;
      text-align: center;
    }
  
    .solution-image {
      margin-bottom: 10px;
      margin-right: 0;
    }
  
    .solution-content {
      border-left: none;
      padding-left: 0;
    }
  }
  
  /* experience ends here */

  /* CTA starts here */
  .brand-section {
    background-color: #0c275e; /* Matches the blue background */
    padding: 60px 20px; /* Padding for spacing */
    color: white; /* Text color */
  }
  
  .section-title {
    font-size: 2rem; /* Adjust title font size */
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .section-description {
    font-size: 20px; /* Description text size */
    margin-bottom: 30px;
  }
  
  .section-button {
    background-color: #ff7b00; /* Orange button color */
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 30px; /* Rounded button */
    color: white;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .section-button:hover {
    background-color: #ff9a3e; /* Slightly lighter shade on hover */
  }
  
  .section-footer {
    font-size: 20px; /* Smaller font for footer */
    margin-top: 20px;
  }
  
  /* Mobile Responsive */
  @media (max-width: 576px) {
    .section-title {
      font-size: 1.5rem; /* Adjust title for small screens */
    }
  
    .section-description {
      font-size: 0.9rem;
    }
  
    .section-button {
      font-size: 0.9rem;
      padding: 8px 16px;
    }
  
    .section-footer {
      font-size: 0.8rem;
    }
  }
  
  /* CTA ends here */