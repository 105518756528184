/* Customize dropdown menu */
.dropdown-menu {
    background-color: #fff; /* Matches navbar background */
    border: none;
    border-radius: 8px;
  }
  
  .dropdown-item {
    /* color: #ffffff; */
    font-size: 14px;
    padding: 10px 20px;
    transition: all 0.3s ease;
  }
  
  .dropdown-item:hover {
    /* background-color: #ff7b00; */
    color: #ff7b00;
  }
  
  @media (max-width: 576px) {
    .navbar-nav {
      text-align: center;
    }
    .dropdown-menu {
      position: relative; /* Prevent overflow issues */
    }
  }