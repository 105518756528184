/* Basic Styling */
.recent-work-section {
    padding: 2rem 1rem;
    background-color: #f8f9fa;
}

.recent-work-section h2 {
    font-weight: bold;
    color: #0056b3;
    margin-bottom: 2rem;
    text-align: center;
}

/* Carousel Container */
.carousel-container {
    position: relative;
    display: flex;
    align-items: center;
}

.scroll-container {
    display: flex;
    overflow: hidden; /* Hide the scrollbar */
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    gap: 1rem; /* Space between items */
    width: 100%;
}

/* Work Item Styling */
.work-item {
    min-width: calc(20% - 1rem); /* Shows 5 items in view */
    /* flex: 0 0 auto; */
    height: 200px;
    /* background-color: #ddd; */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    border-radius: 8px;
    margin-right: 20px;
}

/* Arrow Button Styling */
.scroll-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 1.5rem;
    cursor: pointer;
    z-index: 1;
    outline: none;
}

.scroll-button.left {
    left: 10px;
}

.scroll-button.right {
    right: 10px;
}

.scroll-button:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

/* Mobile Adjustments */
@media (max-width: 768px) {
    .work-item {
        min-width: calc(80% - 1rem); /* Shows 2 items in view */
        height: 150px;
    }
}
