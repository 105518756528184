/* General Styling */
.insights-section {
    margin-top: 50px;
    padding: 50px 20px;
    background-color: #f9f9f9;
    color: #333;
  }
  
  .insights-section h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .insights-section p {
    font-size: 1rem;
    line-height: 1.6;
  }
  
  .insight-card {
    background-color: #eaf3ff;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .insight-card:hover {
    transform: translateY(-5px);
  }
  
  .insight-card .icon-container {
    font-size: 2.5rem;
    color: #004aad;
    margin-bottom: 15px;
  }
  
  .insight-card h3 {
    font-size: 1.25rem;
    margin-bottom: 10px;
    color: #333;
  }
  
  .insight-card p {
    font-size: 1rem;
    color: #555;
  }
  
  /* Button Styling */
  .insights-section .btn-primary {
    background-color: #004aad;
    border: none;
    padding: 10px 20px;
    border-radius: 25px;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .insights-section .btn-primary:hover {
    background-color: #004aad;
  }
  
  /* Mobile Responsiveness */
  @media (max-width: 768px) {
    .insight-card {
      margin-bottom: 20px;
    }
  }
  
  @media (max-width: 576px) {
    .insights-section h2 {
      font-size: 1.5rem;
    }
  }


  /* General Styling */
.technologies-section {
    padding: 50px 20px;
    background-color: #f9f9f9;
    color: #333;
  }
  
  .technologies-section h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #222;
  }
  
  
  .technology-card {
    background-color: #eaf3ff;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .technology-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
  }
  
  .technology-card .icon-container {
    font-size: 2.5rem;
    color: #004aad;
    margin-bottom: 15px;
  }
  
  .technology-card h3 {
    font-size: 1.25rem;
    margin-bottom: 10px;
    color: #222;
  }
  
  .technology-card p {
    font-size: 1rem;
    color: #555;
  }
  
  /* Mobile Responsiveness */
  @media (max-width: 768px) {
    .technology-card {
      margin-bottom: 20px;
    }
  }
  
  @media (max-width: 576px) {
    .technologies-section h2 {
      font-size: 1.5rem;
    }
    .technology-card p {
      font-size: 0.9rem;
    }
  }
  