.chat-wrapper {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
  }
  
  .chat-toggle-button {
    font-size: 24px;
    background-color: #0056B3;
    color: white;
    border: none;
    padding: 12px;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .chat-assistant-container {
    width: 100%;
    max-width: 320px;
    height: auto;
    max-height: 500px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    position: fixed;
    bottom: 80px;
    right: 20px;
    display: flex;
    flex-direction: column;
    overflow-y: auto; /* Enable vertical scrolling */
  }

/* scroll */
.scrollable-container {
    max-height: 300px; /* Adjust height as needed */
    overflow-y: auto; /* Enable vertical scrolling */
  }
  
  /* Custom scrollbar for WebKit browsers (Chrome, Safari) */
  .scrollable-container::-webkit-scrollbar {
    width: 6px; /* Set scrollbar width */
  }
  
  .scrollable-container::-webkit-scrollbar-track {
    background: #f1f1f1; /* Track color */
    border-radius: 3px;
  }
  
  .scrollable-container::-webkit-scrollbar-thumb {
    background-color: #0056B3; /* Scrollbar thumb color */
    border-radius: 3px;
  }
  
  /* Optional: Hover effect for the scrollbar thumb */
  .scrollable-container::-webkit-scrollbar-thumb:hover {
    background-color: #0056B3;
  }
  
  /* For Firefox */
  .scrollable-container {
    scrollbar-width: thin;
    scrollbar-color: #0056B3 #f1f1f1;
  }
  

  
  .chat-assistant-header {
    padding: 16px;
    background-color: #0056B3;
    color: white;
    text-align: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow */
  }
  
  .chat-assistant-title {
    font-weight: bold;
    font-size: 1.2em;
  }
  
  .chat-assistant-subtitle,
  .chat-assistant-description {
    font-size: 0.9em;
    margin: 4px 0;
  }
  
  .chat-assistant-options,
  .chat-assistant-subtopics {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  
  .chat-assistant-option-btn {
    background-color: #f1f1f1;
    border: none;
    padding: 10px;
    font-size: 0.9em;
    cursor: pointer;
    text-align: left;
    border-radius: 4px;
  }
  
  .chat-assistant-option-btn:hover {
    background-color: #e0e0e0;
  }
  
  .back-btn {
    margin-bottom: 8px;
    background-color: #0056B3;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .chat-assistant-body {
    padding: 10px;
    background-color: #f9f9f9;
    border-top: 1px solid #ddd;
  }
  
  .chat-assistant-message {
    display: flex;
    align-items: flex-start;
  }
  
  .chat-assistant-text p {
    margin: 0;
    font-size: 0.9em;
  }
  
