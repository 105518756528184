.whatsapp-button {
    display: flex;
    align-items: center;
    background-color: #25D366;
    color: white;
    border: none;
    padding: 15px 15px;
    border-radius: 50px;
    /* font-size: 25px; */
    cursor: pointer;
    position: fixed;
    bottom: 80px;
    right: 20px;
    z-index: 1000;
    width: 50px;
    height: 50px;
  }
  
  .whatsapp-button i {
    font-size: 25px;
  }
  
  .whatsapp-button:hover {
    background-color: #1ebe5d;
  }