.expertise-card {
    background: #f9f9f9;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    padding-bottom: 40px;
    padding-top: 40px;
  }
  
  .expertise-card:hover {
    transform: translateY(-5px);
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
  }
  
  .expertise-icon {
    font-size: 2rem;
    color: #ff7b00;
    margin-bottom: 15px;
  }
  
  .item-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #23156d;
    margin-bottom: 10px;
  }
  
  .item-description {
    font-size: 1rem;
    color: #555;
    line-height: 1.5;
  }
  