
.contact-section {
    background-color: var(--secondary-color);
    padding: 40px 20px;
  }
  
  .contact-section h2 {
    color: var(--text-color);
    margin-bottom: 30px;
  }
  
  .contact-card {
    background: white;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    margin: 10px;
    transition: transform 0.2s, box-shadow 0.2s;
  }
  
  .contact-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  
  .contact-icon {
    font-size: 30px;
    color: var(--primary-color);
    margin-bottom: 10px;
  }
  
  .contact-card h4 {
    margin-bottom: 10px;
    color: var(--primary-color);
  }
  
  .contact-card p {
    color: var(--text-color);
    font-size: 16px;
  }
  
  @media (max-width: 768px) {
    .contact-card {
      margin: 20px 0;
    }
  }