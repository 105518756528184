.faq-section {
    padding: 2rem;
    background-color: #f4f9fc;
    text-align: center;
}

.faq-title {
    padding-top: 60px;
    padding-bottom: 20px;
    font-size: 2rem;
    font-weight: bold;
    color: #0056b3;
}

.faq-description {
    color: #5a5a5a;
    font-size: 1rem;
    margin: 0.5rem 0 2rem;
}

.faq-container {
    padding-bottom: 60px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}

.faq-item {
    background-color: #ffffff;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: box-shadow 0.2s;
}

.faq-item.active {
    background-color: #eaf3fc;
    border-left: 4px solid #0056b3;
}

.faq-item:hover {
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.faq-question {
    font-size: 1rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #333;
}

.faq-answer {
    overflow: hidden;
    max-height: 0;
    margin-top: 0.5rem;
    color: #666;
    font-size: 0.9rem;
    line-height: 1.4;
    text-align: left;
    transition: max-height 0.3s ease;
}

.faq-item.active .faq-answer {
    max-height: 200px; /* Set this based on expected content size */
}

.faq-icon {
    font-size: 1.5rem;
    color: #0056b3;
}

/* Responsive Design */
@media (max-width: 768px) {
    .faq-container {
        grid-template-columns: 1fr;
    }
}
