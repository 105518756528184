.tech-solutions-section {
    padding: 60px 0;
    background-color: #f9f9f9;
  }
  
  .tech-solutions-section h2 {
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 40px;
  }
  
  .tech-solutions-section .highlight {
    color: #0056b3;
  }
  
  .tech-solutions-section p {
    padding-top: 20px;
    color: #555;
    margin: 0 auto;
  }
  
  .tech-card {
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    text-align: left;
    margin-bottom: 20px;
    margin-top: 20px;
    min-height: 180px;
  }
  
  .tech-card:hover {
    transform: translateY(-10px);
  }
  
  .tech-card h5 {
    font-weight: bold;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .tech-card-peach .icon {
    color: #ff6600;
  }
  
  
  .tech-card p {
    margin: 0;
    color: #333;
  }
  
  .tech-card-blue {
    background-color: #e6f0ff;
    border-left: 5px solid #0056b3;
  }
  
  .tech-card-peach {
    background-color: #ffe6cc;
    border-left: 5px solid #ff6600;
  }
  
  .btn-primary {
    background-color: #0d3d6d;
    border-color: #0d3d6d;
    padding: 10px 30px;
    border-radius: 30px;
    margin-bottom: 30px;
  }
  
  @media (max-width: 768px) {
    .tech-card {
      margin: 0 auto;
      text-align: center;
    }
  
    .tech-card h5,
    .tech-card p {
      text-align: center;
    }
  }
  