/* Numbering Section Styling */
.numbers-section {
    background-color: #f5f7f9;
    padding: 60px 0;
}

.number-item {
    position: relative;
    text-align: center;
    font-weight: bold;
    color: #000;
    margin-bottom: 30px; /* Space between items */
}

.number-item .number {
    font-size: 120px;            /* Large font size for desktop */
    color: rgb(198, 196, 196);   /* Light grey color for background number */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;                  /* Place behind the text */
    line-height: 1;
}

.number-item .number-text {
    position: relative;
    z-index: 2;                  /* Place in front of the number */
    font-size: 18px;
    color: #000;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
    .numbers-section {
        padding: 30px 0;         /* Reduce padding on smaller screens */
    }

    .number-item .number {
        font-size: 60px;         /* Smaller font size for mobile */
    }

    .number-item .number-text {
        font-size: 16px;         /* Adjust font size for readability on mobile */
    }
}

@media (max-width: 480px) {
    .number-item .number {
        font-size: 50px;         /* Further reduce font size for very small screens */
    }

    .number-item .number-text {
        font-size: 14px;         /* Further reduce text size */
        padding-top: 20px;       /* Add spacing for visual separation */
    }

    .number-item {
        margin-bottom: 20px;     /* Reduce space between items on very small screens */
    }
}
