
/* Card Styling */
.solution-card {
    background: #e9f3ff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .solution-card:hover {
    transform: translateY(-8px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  }
  
  /* Icon Circle */
  .icon-circle {
    width: 70px;
    height: 70px;
    background-color: #0056b3;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
  }
  
  .card-title {
    font-size: 1.25rem;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .card-description {
    font-size: 0.95rem;
    color: #555;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .solution-card {
      padding: 15px;
    }
  
    .icon-circle {
      width: 60px;
      height: 60px;
      font-size: 20px;
    }
  
    .card-title {
      font-size: 1.1rem;
    }
  
    .card-description {
      font-size: 0.9rem;
    }
  }

  /* section 3 design */
  .card {
    border: none;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  }
  
  .card-img-top {
    height: 200px;
    object-fit: cover;
    border-bottom: 2px solid #f0f0f0;
  }
  
  .card-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 10px;
  }
  
  .container {
    max-width: 1200px;
  }
  
  .responsive-image {
    width: 100%; /* Ensures the image takes the full width of the container */
    max-width: 1100px; /* Limits the maximum width to 1100px for larger screens */
    height: auto; /* Maintains the aspect ratio of the image */
    display: block; /* Removes unnecessary gaps below the image */
    margin: 0 auto; /* Centers the image in the container */
  }
  