.about-hero {
    height: 100vh;
    width: 100%;
    background: url('../../components/assets/statixpro_new_website.png') no-repeat center/cover;
    color: white;
    /* text-align: center; */
    position: relative;
    padding: 30px;
    
}
.about-hero-content{
    padding-left: 80px;
    padding-right: 80px;
}

.about-hero-content h1 {
    margin-top: 130px;
    font-size: 50px;
    font-weight: bold;
}

.about-hero-content p {
    font-size: 22px;
    line-height: 1.5;
    padding-top: 20px;
}


.about-hero-content span {
    color: #ff7b00;
    font-weight: bold;
}

@media (max-width: 768px) {
    .about-hero  {
        height: 90vh;
        width: 100%;
        /* padding: 20px; */
        padding-top: 10px;
    }
    

    .navbar-brand {
        flex-grow: 1;
        text-align: left;
    }

    .navbar-toggler {
        position: absolute;
        right: 15px;
    }

    .about-hero-content h1 {
        margin-top: 50px;
        font-size: 32px;
    }

    .about-hero-content p {
        font-size: 16px;
        
    }

    .hero-buttons {
        flex-direction: column;
        gap: 10px;
    }

    .view-button, .contact-button {
        width: 100%;
        padding: 8px 16px;
        font-size: 14px;
    }
    .about-hero-content{
        padding-left: 0px;
        padding-right: 0px;
    }
}


/* Our story starts here */
.our-story-section {
    background-color: #f7f9fc;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .story-container {
    display: flex;
    width: 100%;
    overflow: hidden;
  }
  
  .side-title {
    background-color: #173e8b; 
    color: #ffffff;
    writing-mode: vertical-rl;
    /* text-orientation: mixed; */
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    padding: 20px;
    letter-spacing: 2px;
  }
  
  .story-content {
    padding: 80px;
    flex: 1;
  }
  
  .story-heading {
    font-size: 39px;
    color: #ff7900; /* Bright orange for heading */
    font-weight: 600;
    margin-bottom: 20px;
  }
  
  .story-text {
    font-size: 16px;
    color: #333333; /* Dark grey text */
    line-height: 1.6;
  }

  
  /* Our Story ends here */

  @media (max-width: 768px) {
    .side-title {
        font-size: 20px;
        padding: 5px;
    }

    .story-heading {
        font-size: 24px;
    }

    .story-text {
        font-size: 14px;
    }
    .story-content {
        padding: 30px;
    }
  }

  /* numbers section starts here */
  .stats-container {
    text-align: center;
    padding: 50px 20px;
    background-color: #fff;
  }
  
  .stats-title {
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 30px;
    color: #002d6d;
  }
  
  .stats-card {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: #ffe4c4;
    padding: 10px;
    border-radius: 10px;
    align-items: center;
  }
  
  .stat-item {
    flex: 1 1 200px;
    margin: 10px;
    text-align: center;
    
  }
  
  .stat-item h4 {
    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: 10px;
  }
  
  .stat-item p {
    font-size: 1rem;
    color: #555;
  }
  
  .stat-divider {
    display: none;
    border-left: 2px solid #858585;
    margin: 0 20px;
    height: 50px;
  }
  
  /* Responsive Design */
  @media (min-width: 768px) {
    .stats-card {
      flex-wrap: nowrap;
    }
  
    .stat-divider {
      display: block;
    }
  }
  
  /* numbers ends here */

  /* Our core values starts here */
  /* Core Values Section */
.core-values-section {
    background-color: #f7f9fc;
    border-radius: 10px;
  }
  
  .core-values-title {
    font-size: 32px;
    font-weight: bold;
    color: #173e8b;
  }
  
  .core-value-item {
    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .core-value-item:hover {
    transform: translateY(-10px);
  }
  
  .core-value-icon {
    width: 80px;
    height: 80px;
  }
  
  .core-value-heading {
    font-size: 20px;
    font-weight: 600;
    color: #ff7b00;
    margin-bottom: 10px;
  }
  
  .core-value-description {
    font-size: 17px;
    color: #333333;
    line-height: 1.6;
  }
  
  /* Our core values ends here */


  /* We Make It Simple, but Significant starts here */
  .simple-significant-container {
    padding-top: 90px;
    padding-bottom: 90px;
    background-color: #fff;
    padding-left: 190px;
    padding-right: 190px;
  }
  
  .title-orange {
    color: #ff7a00; /* Match the orange color */
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .description {
    color: #000;
    font-size: 20px;
    line-height: 1.6;
    margin-bottom: 2rem;
  }
  
  .custom-btn {
    font-weight: 500;
    font-size: 1rem;
    padding: 0.8rem 1.5rem;
    background-color: #0056b3; /* Match blue button */
    border: none;
    border-radius: 50px;
    width: auto; /* Adjust for smaller screens */
  }
  
  .custom-btn:hover {
    background-color: #003d80; /* Darker blue on hover */
  }
  
  /* Media Queries for Mobile Responsiveness */
  @media (max-width: 768px) {
    .simple-significant-container {
      padding: 1.5rem 1rem;
    }
  
    .title-orange {
      font-size: 1.5rem;
      margin-top: 40px;
    }
  
    .description {
      font-size: 0.9rem;
      line-height: 1.4;
    }
  
    .custom-btn {
      width: 100%; /* Make button full width for mobile */
      padding: 0.7rem 1rem;
      font-size: 0.9rem;
      margin-bottom: 40px;
    }
  }
  
  /* We Make It Simple, but Significant ends here */

/* table starts here */
  .table-wrapper {
    background-color: rgba(255, 255, 255, 0.8); /* White with transparency */
    padding: 20px;
    border-radius: 10px;
    padding-bottom: 0px;

  }
  
  .table {
    border-collapse: collapse; /* Ensures no double borders */
    margin: 0 auto;
    max-width: 100%;
  }
  
  .table th, 
  .table td {
    border: none; /* Makes row lines invisible */
    font-size: 16px;
  }
  
  .table-header {
    background-color: rgba(232, 240, 255, 0.8); /* Light blue with transparency */
    color: #ff6a00;
    font-weight: bold;
  }
  
  .key-aspects {
    color: #ff6a00;
    font-weight: 600;
  }
  
  .table td {
    vertical-align: middle;
  }
  
  @media (max-width: 768px) {
    .table-wrapper {
      padding: 10px;
    }
  
    .table td {
      font-size: 14px;
      padding: 10px;
    }
  }
    

  /* call to action starts here */
  /* CTA Section */
.cta-section {
    background-color: #0e376e; /* Matches the dark blue background */
    /* padding: 50px 20px; */
    min-height: 200px; /* Ensures sufficient spacing */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    padding-bottom: 70px;
    padding-top: 70px;
  }
  
  .cta-heading {
    font-size: 28px;
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 20px;
    line-height: 1.5;
  }
  
  .cta-heading .highlight {
    color: #ff7b00; /* Orange color for highlighted text */
  }
  
  .cta-button {
    background-color: #ff7b00; /* Button orange color */
    border: none;
    border-radius: 25px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    padding: 12px 25px;
    transition: all 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .cta-button:hover {
    background-color: #e96e00;
    transform: scale(1.05); /* Slightly enlarges on hover */
  }
  
  .gfg{
    border-spacing: 0 15px;
  }
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .cta-heading {
      font-size: 22px;
      line-height: 1.4;
    }
  
    .cta-button {
      padding: 10px 20px;
      font-size: 14px;
    }
  }
  