
  .btn-gradient {
    background: linear-gradient(135deg, #f7b42c, #fc575e);
    border: none;
    color: #fff;
    padding: 10px 20px;
    border-radius: 25px;
    transition: all 0.3s ease;
  }
  .btn-gradient:hover {
    transform: scale(1.05);
  }
  
  /* Section Titles */
  .section-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 40px;
  }
  .section-description {
    font-size: 20px;
    /* color: #555; */
  }
  
  /* Who Should Apply */
  .apply-card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: all 0.3s ease;
  }
  .apply-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  }
  
  /* Steps to Apply */
  .steps-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .step {
    text-align: center;
    max-width: 200px;
    margin: 15px;
  }
  .step-number {
    font-size: 2rem;
    color: #0c305a;
    font-weight: bold;
    margin-bottom: 10px;
  }
  