/* General Styling */
.address-bar {
  background-color: #f8f9fa;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Hanken Grotesk", sans-serif;
}

/* Contact Info (Left Side) */
.contact-info {
  display: flex;
  align-items: center;
  gap: 20px;
  color: #333;
}

.contact-item {
  display: flex;
  align-items: center;
  font-size: 14px;
  transition: color 0.3s ease;
}

.contact-item:hover {
  color: #ff7b00;
}

.contact-item a {
  text-decoration: none; /* Remove underline */
  color: #000; /* Set link color to black */
}

.icon {
  margin-right: 8px;
  font-size: 18px;
}

/* Social Icons (Right Side) */
.social-icons {
  display: flex;
  gap: 15px;
}

.social-icon {
  font-size: 18px;
  color: #333;
  transition: color 0.3s ease;
}

/* Mobile View Adjustments */
@media (max-width: 768px) {
  /* Stack contact items horizontally and hide text */
  .contact-info {
      flex-direction: row;
      gap: 15px;
  }

  .contact-item a,
  .contact-item span {
      display: none;
  }

  .icon {
      font-size: 20px;
      margin-right: 0;
  }

  /* Hide the clock icon on mobile */
  .clock-icon {
      display: none;
  }
  
  /* Make social icons centered in mobile view */
  .social-icons {
      justify-content: flex-end;
  }

  .address-bar {
    padding-left: 20px;
    padding-right: 20px;
  }
}
