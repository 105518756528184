.policy-header h1 {
  font-size: 2.5rem;
  font-weight: bold;
}

.policy-header p {
  font-size: 1rem;
  color: #6c757d;
}

