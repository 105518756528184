.modal {
    background-color: rgba(0, 0, 0, 0.8); /* Dimmed background */
  }
  
  .modal-content {
    background: none; /* Remove default white background */
    border: none; /* Remove border */
  }
  
  .modal-body img {
    border-radius: 10px; /* Optional: Rounded corners for the image */
  }
  