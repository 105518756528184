/* General Styling for Desktop and Larger Screens */
.hero {
    height: 100vh;
    width: 100%;
    background: url('../../components/assets/statixpro_background.jpg') no-repeat center/cover;
    color: white;
    /* text-align: center; */
    position: relative;
    padding: 30px;
}

.home_nav_items{
    padding-left: 20px;
    color: #fff;
}

.logo-img {
    max-width: 350px;
    width: 200px;
}

.meet-button {
    background-color: #ff7b00;
    border: none;
    border-radius: 50px;
    padding: 10px 20px;
    color: white;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.meet-button:hover {
    background-color: #e66b00;
}

.hero-content h1 {
    margin-top: 130px;
    font-size: 58px;
    font-weight: bold;
}

.hero-content p {
    font-size: 24px;
    line-height: 1.5;
    padding-top: 20px;
}

.hero-content span {
    color: #ff7b00;
    font-weight: bold;
}

.hero-buttons .view-button, .hero-buttons .contact-button {
    padding: 10px 30px;
    border-radius: 50px;
    font-size: 16px;
    margin-top: 20px;
}

.view-button {
    background-color: #FF8E2B;
    color: #fff;
    margin-right: 15px;
}

.view-button:hover {
    background-color: #FF8E2B;
    color: #fff;
}



  /* what we do section */
  /* Styling for Services Section */
.services-section {
    background-color: #ffffff;
    padding: 60px 0;
    margin-top: 20px;
  }
  
  .section-subtitle {
    color: #ff7f24; /* Orange color for subtitle */
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: 500;
  }
  
  .section-title {
    font-size: 37px;
    font-weight: bold;
    color: #000000;
    margin-bottom: 30px;
  }
  
  /* Service items styling */
  .service-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
    padding-top: 20px;
  }
  
  .service-item {
    display: inline-flex;
    background-color: #ff7f24; /* Orange background color */
    color: #ffffff;
    padding: 10px 15px;
    border-radius: 20px;
    font-size: 17px;
    font-weight: 500;
    white-space: nowrap; /* Keeps the text in one line */
    transition: transform 0.3s ease;
  }
  
  .service-item:hover {
    transform: scale(1.05); /* Slight zoom effect on hover */
  }
  
  /* testimonial section starts  here */
  :root {
    --primary-color: #3b82f6; /* Blue */
    --card-bg: #ffffff;
    --text-color: #333333;
    --secondary-text: #666666;
    --border-radius: 15px;
  }
  
  .testimonial-section {
    background-color: #f9f9f9;
  }
  
  .testimonial-section p {
    color: var(--secondary-text);
    font-size: 16px;
  }
  
  .testimonial-card {
    background-color: var(--card-bg);
    border: 1px solid #e0e0e0;
    border-radius: var(--border-radius);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
  }
  
  .testimonial-card:hover {
    transform: translateY(-5px);
  }
  
  .quote-icon {
    font-size: 24px;
    color: var(--primary-color);
  }
  
  .testimonial-card p {
    color: var(--text-color);
    font-size: 16px;
    line-height: 1.6;
  }
  
  .testimonial-card h5 {
    font-size: 16px;
    font-weight: bold;
    color: var(--text-color);
  }

  /* Responsive Styling */
  @media (max-width: 768px) {
    .section-title {
      font-size: 20px;
      padding: 0 10px;
    }
  
    .service-item {
      font-size: 13px;
      padding: 8px 12px;
    }
  }
  
  @media (max-width: 576px) {
    .section-title {
      font-size: 18px;
      margin-bottom: 20px;
    }
  
    .service-item {
      font-size: 12px;
      padding: 7px 10px;
    }
  }
  
  
  /* Mobile Responsive Styling */
  @media (max-width: 768px) {
    .numbers-section {
      padding: 40px 0;  /* Reduce padding for mobile */
    }
  
    .number-item {
      margin-bottom: 40px;  /* Increase spacing between items */
    }
  
    .number-item .number {
      font-size: 80px;  /* Smaller font size for the number on mobile */
    }
  
    .number-item .number-text {
      font-size: 16px;  /* Adjust text size to fit better on smaller screens */
      margin-top: 10px;  /* Add some space above the text */
    }
  }
  
  @media (max-width: 576px) {
    .numbers-section {
      padding: 30px 0;
    }
  
    .number-item .number {
      font-size: 60px;  /* Further reduce font size for very small screens */
    }
  
    .number-item .number-text {
      font-size: 14px;
    }
  }
  

/* Mobile View Adjustments */
@media (max-width: 768px) {
    .hero {
        height: 90vh;
        width: 100%;
    }

    .navbar-brand {
        flex-grow: 1;
        text-align: left;
    }

    .navbar-toggler {
        position: absolute;
        right: 15px;
    }

    .hero-content h1 {
        margin-top: 80px;
        font-size: 32px;
    }

    .hero-content p {
        font-size: 16px;
        
    }

    .hero-buttons {
        flex-direction: column;
        gap: 10px;
    }

    .view-button, .contact-button {
        width: 100%;
        padding: 8px 16px;
        font-size: 14px;
    }
}

/* why us section */
/* General Section Styling */
.why-us-section {
    background-color: #f2f6fa; /* Light grey background color */
    padding: 60px 0;
  }
  
  .section-subtitle {
    color: #000; /* Black color for subtitle */
    font-size: 18px;
    font-weight: 500;
  }
  
  .section-title {
    font-size: 30px;
    font-weight: bold;
    color: #0056b3; /* Blue color */
    margin-bottom: 40px;
  }
  
  /* Features Styling */
  .feature-title {
    font-size: 20px;
    font-weight: bold;
    color: #000000;
    text-align: left;
    /* margin-top: 20px; */

  }
  
  .feature-description {
    font-size: 18px;
    color: #333333;
    line-height: 1.6;
    text-align: left;

  }
  
  /* Responsive Styling */
  @media (max-width: 768px) {
    .section-title {
      font-size: 24px;
      padding: 0 10px;
    }
  
    .feature-title {
      font-size: 18px;
    }
  
    .feature-description {
      font-size: 16px;
    }
  }
  
  @media (max-width: 576px) {
    .section-title {
      font-size: 20px;
    }
  
    .feature-title {
      font-size: 15px;
    }
  
    .feature-description {
      font-size: 13px;
    }
    .whyus-features{
        padding: 10px;
    }
  }
  

/* Basic Styling */

.strategy-section {
  background-image: url('../../components/assets/flowpng.png'); /* Replace with the actual path to your image */
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  padding-top: 50px;
  padding-bottom: 50px;
  padding: 2rem 1rem;
  position: relative;
}

.strategy-section h2 {
  font-weight: bold;
  color: #0056b3;
  margin-bottom: 2rem;
  margin-top: 70px;
}

.strategy-steps {
  padding-top: 30px;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 2rem;
  position: relative;
}

.circle {
  display: inline-block;
  background-color: #f57c00;
  color: white;
  font-weight: bold;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.step-content {
  padding: 0 15px; /* Add padding for better spacing */
}

.step-content h5 {
  font-weight: bold;
}

.content-split {
  display: flex;
  justify-content: space-between;
  text-align: left;
}

.content-split ul {
  list-style-type: disc;
  padding-left: 20px;
}

.arrow {
  font-size: 1.5rem;
  color: #666;
  margin-top: 1rem;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .strategy-steps {
      display: flex;
      /* flex-direction: column; */
  }
  .step {
      flex-direction: column;
      align-items: center;
      margin-bottom: 2rem;
      padding: 0 10px; /* Add padding for spacing on mobile */
  }
  .step-content {
      padding: 0 20px; /* Add extra padding for mobile view */
      text-align: center; /* Ensure text is centered */
  }
  .arrow {
      display: none;
  }
}

.contact-section {
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .contact-image {
    width: 500px; /* Ensure it resizes responsively */
    height: auto;
    border-radius: 10px;
  }
  
 
  .contact-section .text-section {
    flex: 1;
    max-width: 600px;
    padding: 1rem;
  }

  .contact-section .text-section h2,
  .contact-section .text-section p {
    color: #ffffff !important;
  }

  
  @media (max-width: 768px) {
    .contact-section {
      text-align: center;
      padding-bottom: 50px;
    }
  
    .text-section {
      margin-top: 1rem;
    }
  
    .contact-image {
      width: 300px; /* Make the image smaller on mobile */
      margin-top: 20px;
    }
  }
  

  /* footer section */
  .footer-dark {
    background-color: #1a1a1a;
    color: #cccccc;
  }
  
  .footer-dark h2 {
    color: #ffffff;
    font-weight: bold;
  }
  
  .footer-dark .btn-orange {
    background-color:#f57c00;
    color: #ffffff;
    border: none;
    padding: 0.5rem 1.5rem;
    border-radius: 30px;
  }
  
  .footer-dark .btn-light {
    background-color: #ffffff;
    color: #333;
    border: none;
    padding: 0.5rem 1.5rem;
    border-radius: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .footer-dark .divider {
    border-top: 1px solid #333333;
  }
  
  .footer-dark ul {
    padding-left: 0;
    list-style: none;
  }
  
  .footer-dark li {
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
  }
  
  .footer-dark .social-icons i {
    margin-right: 1rem;
    color: #ffffff;
    font-size: 1.2rem;
    cursor: pointer;
  }
  
  .footer-dark .social-icons i:hover {
    color: #ff6b6b;
  }
  
  @media (max-width: 768px) {
    .footer-dark {
      text-align: center;
    }
  
    .footer-dark .social-icons {
      justify-content: center;
    }
  }
  

  .lets-talk-btn{
    background-color: #0056b3;
    color: #fff;
    width:100px;
  }
  .lets-talk-btn:hover{
    background-color: #666;
    color: #fff;
  }